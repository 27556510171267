{
  "name": "@civic/solana-gateway-react",
  "version": "0.6.0",
  "main": "./dist/cjs/index.js",
  "module": "./dist/esm/index.js",
  "types": "./dist/esm/index.d.ts",
  "repository": "git@github.com:civicteam/ociv-gatekeeper.git",
  "dependencies": {
    "@identity.com/prove-solana-wallet": "^0.2.3",
    "@identity.com/solana-gateway-ts": "^0.7.0",
    "@solana/web3.js": "^1.33.0",
    "@types/styled-components": "^5.1.15",
    "fetch-retry-ts": "^1.1.24",
    "iframe-resizer-react": "^1.1.0",
    "ramda": "^0.27.1",
    "styled-components": "^5.3.1"
  },
  "peerDependencies": {
    "react": "^17.0.2",
    "react-dom": "^17.0.2"
  },
  "scripts": {
    "test": "yarn lint && SKIP_PREFLIGHT_CHECK=true TEST_RETRY_MULTIPLIER=0.01 react-scripts test --env=./src/custom-env.js --testMatch '**/*.test.{ts,tsx}' --roots ./test",
    "lint": "eslint '{src,test}/**/*.ts' --max-warnings 0",
    "lint:fix": "yarn lint --fix",
    "clean": "rm -rf ./dist",
    "build": "yarn clean && yarn build:esm && yarn build:cjs",
    "build:esm": "tsc --noEmit false --outDir dist/esm -p tsconfig.build.json",
    "build:cjs": "tsc --noEmit false --module commonjs --outDir dist/cjs -p tsconfig.build.json",
    "prepare": "yarn build",
    "script": "ts-node -O '{\"module\":\"commonjs\"}' --transpile-only --files"
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "@testing-library/jest-dom": "^5.14.1",
    "@testing-library/react": "^11.1.0",
    "@testing-library/react-hooks": "^7.0.2",
    "@testing-library/user-event": "^12.1.10",
    "@types/bs58": "^4.0.1",
    "@types/fetch-mock": "^7.3.5",
    "@types/jest": "^26.0.15",
    "@types/mocha": "^8.2.3",
    "@types/node": "^12.0.0",
    "@types/node-fetch": "^2.5.12",
    "@types/react": "^17.0.0",
    "@types/react-addons-test-utils": "^0.14.26",
    "@types/react-dom": "^17.0.0",
    "@types/react-modal": "^3.12.1",
    "@types/react-router-dom": "^5.1.8",
    "@types/react-test-renderer": "^17.0.1",
    "awscred": "^1.5.0",
    "axios": "^0.21.1",
    "bs58": "^4.0.1",
    "copyfiles": "^2.4.1",
    "csstype": "^3.0.9",
    "enzyme": "^3.11.0",
    "eslint": "^7.2.0",
    "eslint-config-airbnb": "18.2.1",
    "eslint-config-airbnb-typescript": "^12.3.1",
    "eslint-config-prettier": "^8.3.0",
    "eslint-plugin-import": "^2.22.1",
    "eslint-plugin-jest": "^24.3.6",
    "eslint-plugin-jsx-a11y": "^6.4.1",
    "eslint-plugin-prettier": "^3.4.0",
    "eslint-plugin-react": "^7.21.5",
    "eslint-plugin-react-hooks": "^1.7.0",
    "jest-cucumber": "^3.0.1",
    "jest-environment-jsdom": "^27.0.6",
    "jest-environment-jsdom-sixteen": "^2.0.0",
    "jest-environment-uint8array": "^1.0.0",
    "node-fetch": "^2.6.1",
    "prettier": "^2.3.2",
    "react": "^17.0.2",
    "react-dom": "^17.0.2",
    "react-scripts": "^4.0.3",
    "react-test-renderer": "^17.0.2",
    "rimraf": "^3.0.2",
    "ts-jest": "^26.1.4",
    "ts-node": "^10.1.0",
    "typescript": "^4.1.2"
  },
  "files": [
    "/dist"
  ]
}
