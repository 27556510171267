import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { SnackbarProvider } from "notistack"
import "./index.css"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

Sentry.init({
	dsn: "https://de540b8f53c943cbac671a4aa2f8b155@o473224.ingest.sentry.io/6236313",
	integrations: [new BrowserTracing()],
  
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
	environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
})

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
	<React.StrictMode>
		<SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
			<App />
		</SnackbarProvider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
