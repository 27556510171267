import { useEffect, useMemo, useState, useCallback } from "react"
import { Wallet, web3, BN } from "@project-serum/anchor"

import styled from "styled-components"
import { Backdrop, Box, Container, Grid, IconButton, LinearProgress, Paper, Stack } from "@mui/material"
import {
	Commitment,
	Connection,
	PublicKey,
	Transaction,
} from "@solana/web3.js"
import { useWallet } from "@solana/wallet-adapter-react"
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui"
import {
	awaitTransactionSignatureConfirmation,
	CandyMachineAccount,
	CANDY_MACHINE_PROGRAM,
	createAccountsForMint,
	getCandyMachineState,
	getCollectionPDA,
	mintOneToken,
	SetupState
} from "./candy-machine"
import { Header } from "./Header"
import { MintButton } from "./MintButton"
import { GatewayProvider } from "@civic/solana-gateway-react"
import MintGallery from "./MintGallery"
import logo from "../assets/images/logo.webp"
import { useSnackbar } from "notistack"
import Countdown from "react-countdown"
import { programs } from "@metaplex/js"
import { MintCountdownRender } from "./MintCountdown"
import TwitterIcon from "@mui/icons-material/Twitter"
import InstagramIcon from "@mui/icons-material/Instagram"
import { toDate, formatNumber } from "./utils"
import AttributeDisplay, { Attribute } from "./AttributeDisplay"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDiscord, faTiktok } from "@fortawesome/free-brands-svg-icons"
import useLocalStorage from "./hooks/useLocalStorage"
import * as Sentry from "@sentry/react"
const { metadata: { Metadata } } = programs

const ConnectButton = styled(WalletDialogButton)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #FFC0CB 0%, #FEF2AE 100%);
  color: black;
  font-size: 16px;
  font-weight: bold;
`

const Heading = styled.h1`
  text-transform: uppercase;
  font-size: calc(1.5625rem + ((1vw - 3.75px) * 1.4887));
`

const RemainingDialog = styled.h1`
  font-size: 32px;
  text-transform: uppercase;
  text-align: center;
`

const Description = styled.h3`
`

const MintInfo = styled.span`
background: #384457;
font-size: calc(1.5625rem + ((1vw - 3.75px) * 1.4887));
color: white;
text-transform: uppercase;
padding: 5px;
`

const MintContainer = styled.div`` // add your owns styles here

export interface HomeProps {
	candyMachineId?: web3.PublicKey;
	connection: web3.Connection;
	startDate: number;
	txTimeout: number;
	rpcHost: string;
}

interface Metadata {
	name: string;
	image: string;
	attributes: Attribute[]
}

const Home = (props: HomeProps) => {
	const [isUserMinting, setIsUserMinting] = useState(false)
	const [isFetchingNFTImage, setIsFetchingNFTImage] = useState(false)
	const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>()
	const [needTxnSplit, setNeedTxnSplit] = useState(true)
	const [setupTxn, setSetupTxn] = useState<SetupState>()
	const [fetchError, setFetchError] = useState(0)
	const [metadatas, setMetadatas] = useLocalStorage<Metadata[]>(`${props.candyMachineId}-metadatas`, [])
	const [selectedMetadata, setSelectedMetadata] = useState<Metadata>()
	const [imageUris, setImageUris] = useState<string[]>([])
	const { enqueueSnackbar } = useSnackbar()
	const [goLiveDate, setGoLiveDate] = useLocalStorage<Date | undefined>("goLiveDate", new Date(Date.UTC(2022, 2, 18, 22, 54, 45)))
	const [remainingApes, setRemainingApes] = useLocalStorage("remainingApes", 4970)
	const [price, setPrice] = useLocalStorage("livePrice", 1.0)

	const rpcUrl = props.rpcHost
	const wallet = useWallet()

	let timeOut: NodeJS.Timeout

	const anchorWallet = useMemo(() => {
		if (
			!wallet ||
			!wallet.publicKey ||
			!wallet.signAllTransactions ||
			!wallet.signTransaction
		) {
			return
		}

		return {
			publicKey: wallet.publicKey,
			signAllTransactions: wallet.signAllTransactions,
			signTransaction: wallet.signTransaction,
		} as Wallet
	}, [wallet])

	const refreshCandyMachineState = useCallback(
		async (commitment: Commitment = "confirmed") => {
			if (!anchorWallet) {
				return
			}

			const connection = new Connection(props.rpcHost, commitment)

			if (!props.candyMachineId) {
				enqueueSnackbar("Your REACT_APP_CANDY_MACHINE_ID value in the .env file doesn't look right! Make sure you enter it in as plain base-58 address!",
					{ variant: "error" })
			}

			if (props.candyMachineId && !isUserMinting && !isFetchingNFTImage) {
				try {
					const cndy = await getCandyMachineState(
						anchorWallet,
						props.candyMachineId,
						connection,
					)
					let active =
						cndy?.state.goLiveDate?.toNumber() < new Date().getTime() / 1000

					if (cndy.state.isSoldOut) {
						active = false
					}

					const [collectionPDA] = await getCollectionPDA(props.candyMachineId)
					const collectionPDAAccount = await connection.getAccountInfo(
						collectionPDA,
					)

					const balance = new BN(
						await connection.getBalance(anchorWallet.publicKey),
					)
					const valid = balance.gte(cndy.state.price)
					if (!valid)
						enqueueSnackbar("You dont have sufficient funds to mint", { variant: "error" })

					cndy.state.isActive = active && valid

					const priceAsNumber = formatNumber.asNumber(cndy.state.price)
					if (priceAsNumber)
						setPrice(priceAsNumber)

					setCandyMachine(cndy)
					setGoLiveDate(toDate(cndy.state.goLiveDate))
					setRemainingApes(cndy.state.itemsRemaining)
					Sentry.setUser({ id: anchorWallet.publicKey.toString() })

					const txnEstimate =
						892 +
						(!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
						(cndy.state.tokenMint ? 66 : 0) +
						(cndy.state.whitelistMintSettings ? 34 : 0) +
						(cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
						(cndy.state.gatekeeper ? 33 : 0) +
						(cndy.state.gatekeeper?.expireOnUse ? 66 : 0)

					setNeedTxnSplit(txnEstimate > 1230)
				} catch (e) {
					if (e instanceof Error) {
						if (
							e.message === `Account does not exist ${props.candyMachineId}`
						) {
							enqueueSnackbar(`Couldn't fetch candy machine state from candy machine with address: ${props.candyMachineId}, using rpc: ${props.rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!`,
								{ variant: "error" })
						} else if (
							e.message.startsWith("failed to get info about account")
						) {
							enqueueSnackbar(`Couldn't fetch candy machine state with rpc: ${props.rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`,
								{ variant: "error" })
						}
					}
					Sentry.captureException(e)
				}
			}
		},
		[anchorWallet, props.candyMachineId, props.rpcHost, isUserMinting, isFetchingNFTImage],
	)

	const renderer = ({ completed }: MintCountdownRender) => {
		if (completed) {
			if (candyMachine?.state.isSoldOut)
				return "Sold Out"
			// Render a completed state
			return `Minting is now Live @ ${price}`
		} else {
			// Render a countdown
			return "Minting is Coming"
		}
	}

	const onSelectImage = (index: number) => {
		setSelectedMetadata(metadatas[metadatas.length - 1 - index])
	}

	const onMint = async (
		beforeTransactions: Transaction[] = [],
		afterTransactions: Transaction[] = [],
	) => {
		try {
			setIsUserMinting(true)
			typeof gtag != "undefined" && gtag("event", "mint-clicked")

			document.getElementById("#identity")?.click()
			if (wallet.connected && candyMachine?.program && wallet.publicKey) {
				let setupMint: SetupState | undefined
				if (needTxnSplit && setupTxn === undefined) {
					enqueueSnackbar("Please sign account setup transaction",
						{ variant: "info" })
					setupMint = await createAccountsForMint(
						candyMachine,
						wallet.publicKey,
					)
					let status: any = { err: true }
					if (setupMint.transaction) {
						status = await awaitTransactionSignatureConfirmation(
							setupMint.transaction,
							props.txTimeout,
							props.connection,
							true,
						)
					}
					if (status && !status.err) {
						setSetupTxn(setupMint)
						enqueueSnackbar("Setup transaction succeeded! Please sign minting transaction",
							{ variant: "info" })
					} else {
						enqueueSnackbar("Mint failed! Please try again!",
							{ variant: "error" })
						setIsUserMinting(false)
						return
					}
				} else {
					enqueueSnackbar("Please sign minting transaction",
						{ variant: "info" })
				}

				typeof gtag != "undefined" && gtag("event", "mint-signed")

				const mintResult = await mintOneToken(
					candyMachine,
					wallet.publicKey,
					beforeTransactions,
					afterTransactions,
					setupMint ?? setupTxn,
				)

				let status: any = { err: true }
				let metadataStatus = null
				if (mintResult) {
					status = await awaitTransactionSignatureConfirmation(
						mintResult.mintTxId,
						props.txTimeout,
						props.connection,
						true,
					)

					metadataStatus =
						await candyMachine.program.provider.connection.getAccountInfo(
							mintResult.metadataKey,
							"processed",
						)
					console.log("Metadata status: ", !!metadataStatus)
				}

				if (status && !status.err && metadataStatus) {
					// manual update since the refresh might not detect
					// the change immediately
					const remaining = remainingApes! - 1
					setRemainingApes(remaining)
					candyMachine.state.isSoldOut = remaining === 0
					setSetupTxn(undefined)
					enqueueSnackbar("Congratulations! Mint succeeded!",
						{ variant: "success" })
					if (mintResult) {
						timeOut = setInterval(async () => await loadMetaData(mintResult.metadataKey), 5000)
						setIsFetchingNFTImage(true)
					}
					refreshCandyMachineState("processed")

				} else if (status && !status.err) {
					enqueueSnackbar("Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.",
						{ variant: "error" })
					refreshCandyMachineState()
				} else {
					enqueueSnackbar("Mint failed! Please try again!",
						{ variant: "error" })
					refreshCandyMachineState()
				}
			}
		} catch (error: any) {
			let message = error.msg || "Minting failed! Please try again!"
			if (!error.msg) {
				if (!error.message) {
					message = "Transaction timeout! Please try again."
				} else if (error.message.indexOf("0x137")) {
					Sentry.captureException(error)
					message = "SOLD OUT!"
				} else if (error.message.indexOf("0x135")) {
					message = "Insufficient funds to mint. Please fund your wallet."
				}
			} else {
				if (error.code === 311) {
					Sentry.captureException(error)
					message = "SOLD OUT!"
					window.location.reload()
				} else if (error.code === 312) {
					message = "Minting period hasn't started yet."
				}
			}

			enqueueSnackbar(message, { variant: "error" })
			refreshCandyMachineState()
		} finally {
			setIsUserMinting(false)
		}
	}

	const loadMetaData = async (tokenPublicKey: PublicKey) => {
		try {
			const ownedMetadata = await Metadata.load(props.connection, tokenPublicKey)
			fetch(ownedMetadata.data.data.uri)
				.then(response => response.json())
				.then(data => {
					if (!metadatas.includes(data)) {
						metadatas.push(data)
						setMetadatas(metadatas)
						reloadGallery()
					}
				})

			setFetchError(0)
			clearInterval(timeOut)
			setIsFetchingNFTImage(false)
		} catch (ex) {
			Sentry.captureException(ex)
			setFetchError(fetchError + 1)
		}
	}

	const reloadGallery = () => {
		setSelectedMetadata(metadatas[metadatas.length - 1])
		const images: string[] = []
		for (let index = metadatas.length - 1; index >= 0; index--) {
			images.push(metadatas[index].image)
		}

		setImageUris(images)
	}

	useEffect(() => {
		const uniqueEntries = Array.from(new Set(metadatas))
		if (uniqueEntries.length != metadatas.length)
			setMetadatas(uniqueEntries)

		reloadGallery()
	}, [metadatas])

	useEffect(() => {
		if (fetchError > 3) {
			clearInterval(timeOut)
			setIsFetchingNFTImage(false)
			enqueueSnackbar("Failed to retrieve image, but check your wallet, as it should be there!", { variant: "info" })
		}

	}, [fetchError])

	useEffect(() => {
		refreshCandyMachineState()
	}, [
		anchorWallet,
		props.candyMachineId,
		props.connection,
		isUserMinting,
		isFetchingNFTImage,
		refreshCandyMachineState,
	])

	return (
		<Container maxWidth='xl'>
			<Grid container>
				<Grid item xs={2}>
					<img src={logo} height="100" />
				</Grid>
				<Grid item xs={10}>
					<Heading>Bored Ape Pastel Club</Heading>
				</Grid>
				<Grid item md sx={{ display: { xs: "none", md: "block" } }}>
					<MintInfo><Countdown renderer={renderer} date={goLiveDate} /></MintInfo>
					<Description>
						<p>The Bored Ape Pastel Club (BAPC) is a collection of 5555 unique Bored Ape Pastel NFTs— unique digital collectibles living on the Solana blockchain.</p>

						<p>Your Bored Ape Pastel will double up as a membership to the exclusive BAPC club where owners will be rewarded with perks and benefits.</p>

						<p>BAPC features never seen before, backgrounds, traits and with a unique utility and amazing charities we welcome you to pick up your pastel and join the very exclusive Bored Ape Pastel Club now!</p>
					</Description>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box sx={{ m: 1, position: "relative" }}>
						<MintGallery imageUris={imageUris} onSelectImage={onSelectImage} />
						<Backdrop sx={{ position: "absolute" }} open={isUserMinting || isFetchingNFTImage}><Stack width='100%' direction='column' justifyContent='center' alignItems='center' spacing={0.0} m={1}><h3>{isUserMinting ? "Minting" : "Fetching NFT"}...</h3><LinearProgress color='secondary' sx={{ width: "100%" }} /></Stack></Backdrop>
					</Box>
					<Paper
						style={{ padding: 24, backgroundColor: "#FAF1D6", borderRadius: 32 }} elevation={24}
					>
						{!wallet.connected ? (
							<ConnectButton>Connect Wallet</ConnectButton>
						) : (
							<>
								<Box sx={{ display: { xs: "block", md: "none" } }}><Header candyMachine={candyMachine} /></Box>
								<MintContainer>
									{candyMachine?.state.isActive &&
										candyMachine?.state.gatekeeper &&
										wallet.publicKey &&
										wallet.signTransaction ? (
										// eslint-disable-next-line
										//@ts-ignore
											<GatewayProvider
												wallet={{
													publicKey:
													wallet.publicKey ||
													new PublicKey(CANDY_MACHINE_PROGRAM),
													signTransaction: wallet.signTransaction,
												}}
												gatekeeperNetwork={
													candyMachine?.state?.gatekeeper?.gatekeeperNetwork
												}
												clusterUrl={rpcUrl}
												options={{ autoShowModal: false }}
											>
												<MintButton
													candyMachine={candyMachine}
													isMinting={isUserMinting}
													onMint={onMint}
												/>
											</GatewayProvider>
										) : (
											<MintButton
												candyMachine={candyMachine}
												isMinting={isUserMinting}
												onMint={onMint}
											/>
										)}
								</MintContainer>
							</>
						)}
					</Paper>
				</Grid>
				<Grid item xs={12} md={4} paddingTop={3}>
					<Paper
						style={{ padding: 1, background: "linear-gradient(180deg, #FFC0CB 0%, #FEF2AE 100%)", borderRadius: 24, marginLeft: 24 }} elevation={24} sx={{ display: { xs: "none", md: "block" } }}
					>
						<RemainingDialog><p>Pastel Apes Remaining</p> <p>{remainingApes}</p></RemainingDialog>
					</Paper>
					<AttributeDisplay attributes={selectedMetadata?.attributes || []}></AttributeDisplay>
				</Grid>
				<Grid item xs={12} sx={{ textAlign: "center" }} paddingTop={3}>
					<IconButton href="http://www.instagram.com/boredapepastelclub" target="_blank"><InstagramIcon sx={{ fontSize: 60, color: "#FFC0CB" }} /></IconButton>
					<IconButton href="http://www.twitter.com/boredapepastels" target="_blank"><TwitterIcon sx={{ fontSize: 60, color: "#FFC0CB" }} /></IconButton>
					<IconButton href="https://discord.gg/QXQNcEp2Pk" target="_blank"><FontAwesomeIcon icon={faDiscord} fontSize="60" color="#FFC0CB" /></IconButton>
					<IconButton href="http://www.tiktok.com/@boredapepastelclub" target="_blank"><FontAwesomeIcon icon={faTiktok} fontSize="60" color="#FFC0CB" /></IconButton>
				</Grid>
			</Grid>
		</Container>
	)
}

export default Home