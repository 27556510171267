import { Paper, Stack } from "@mui/material"
import styled from "styled-components"

export interface Attribute {
	trait_type: string
	value: string
}

export interface AttributeDisplayProps {
	attributes: Attribute[]
}

const PropertyStyled = styled(Paper)`
background: linear-gradient(90deg, #7edeab 0%, #e7bea2 100%);
margin: 10px;
padding: 4px;
`

const Item = styled.span`
color: white;
`

const AttributeTitle = styled.h3`
color: #e3b5fb;
margin: 10px;
text-transform: uppercase;
`

const AttributeDisplay = (props: AttributeDisplayProps) => {

	return <>{props.attributes?.length?(<AttributeTitle>Properties</AttributeTitle>):(<></>)}{
		props.attributes?.map((x, index) => {
			return <PropertyStyled key={index}><Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={0}
			>
				<Item>{x.trait_type}</Item>
				<Item>{x.value}</Item>
			</Stack>
			</PropertyStyled>
		})
	}</>
}

export default AttributeDisplay