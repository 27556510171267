/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react"
import * as Sentry from "@sentry/react"

/**
 * React Hook wrapper for LocalStorage
 * @usage has the same API of useState
 * @param key storage key like localStorage.getItem('key')
 * @param initialValue
 */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useLocalStorage<p>(
	key: string,
	initialValue: p
): [p, (value: p) => void] {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState<p>(() => {
		try {
			const item = window.localStorage.getItem(key)
			return item ? JSON.parse(item) : initialValue
		} catch (error) {
			Sentry.captureException(error)
			return initialValue
		}
	})

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value: p) => {
		try {
			// Save state
			setStoredValue(value)
			window.localStorage.setItem(key, JSON.stringify(value))
		} catch (error) {
			/* istanbul ignore next */
			Sentry.captureException(error)
		}
	}

	return [storedValue, setValue]
}
