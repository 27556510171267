import styled from "styled-components"
import { CandyMachineAccount } from "./candy-machine"
import { GatewayStatus, useGateway } from "@civic/solana-gateway-react"
import { useEffect, useState } from "react"
import { Button, CircularProgress } from "@mui/material"

export const CTAButton = styled(Button)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #FFC0CB 0%, #FEF2AE 100%);
  color: black;
  font-size: 16px;
  font-weight: bold;
`

export const MintButton = ({
	onMint,
	candyMachine,
	isMinting,
}: {
  onMint: () => Promise<void>;
  candyMachine?: CandyMachineAccount;
  isMinting: boolean;
}) => {
	const { requestGatewayToken, gatewayStatus } = useGateway()
	const [clicked, setClicked] = useState(false)

	useEffect(() => {
		if (gatewayStatus === GatewayStatus.ACTIVE && clicked) {
			onMint()
			setClicked(false)
		}
	}, [gatewayStatus, clicked, setClicked, onMint])

	const getMintButtonContent = () => {
		if (candyMachine?.state.isSoldOut) {
			return "SOLD OUT"
		} else if (isMinting) {
			return <CircularProgress />
		} else if (candyMachine?.state.isPresale) {
			return "PRESALE MINT"
		} else if (clicked && candyMachine?.state.gatekeeper) {
			return <CircularProgress />
		}

		return "MINT"
	}

	return (
		<CTAButton
			disabled={
				clicked ||
        candyMachine?.state.isSoldOut ||
        isMinting ||
        !candyMachine?.state.isActive
			}
			onClick={async () => {
				setClicked(true)
				if (candyMachine?.state.isActive && candyMachine?.state.gatekeeper) {
					if (gatewayStatus === GatewayStatus.ACTIVE) {
						setClicked(true)
					} else {
						await requestGatewayToken()
					}
				} else {
					await onMint()
					setClicked(false)
				}
			}}
			variant="contained"
		>
			{getMintButtonContent()}
		</CTAButton>
	)
}
