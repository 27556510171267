import { Avatar, Chip } from "@mui/material"
import styled from "styled-components"
import premintpic from "../assets/images/premint.webp"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { useState } from "react"
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 1
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	}
}

export interface MintGalleryProps {
	imageUris?: string[]
	onSelectImage?: (key: number) => void
}

const GalleryContainer = styled.div`
position: relative;
text-align: center;
border: 8px solid #FFC0CB;
border-radius: 45px;
max-width: 500px;
background: #FFC0CB;
`

const GalleryItem = styled.img`
width:100%;
border-radius: 45px;
`

const NavLabel = styled.div`
position: absolute;
bottom: 8px;
right: 16px;
`

const MintGallery = (props: MintGalleryProps) => {

	const [indexSelected, setIndexSelected] = useState(1)

	function AfterCarouselChange(currentSlide: number): void {
		setIndexSelected(currentSlide + 1)
		props.onSelectImage && props.onSelectImage(currentSlide)
	}

	return (<GalleryContainer>
		{props.imageUris?.length ? <>
			<Carousel responsive={responsive} autoPlay={false} shouldResetAutoplay={false} afterChange={(_previousSlide, { currentSlide }) => AfterCarouselChange(currentSlide)} >
				{
					props.imageUris.map((x, index) => {
						return <GalleryItem key={index} src={x} loading="lazy" />
					})
				}
			</Carousel>
			<NavLabel>
				<Chip avatar={<Avatar>{indexSelected}</Avatar>} label={"/ " + props.imageUris.length} color='primary' />
			</NavLabel>
		</> : <GalleryItem src={premintpic} />}
	</GalleryContainer>)
}

export default MintGallery