import "./App.css"
import { useMemo } from "react"
import Home from "./Home"

import { clusterApiUrl } from "@solana/web3.js"
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base"
import {
	GlowWalletAdapter,
	LedgerWalletAdapter,
	PhantomWalletAdapter,
	SlopeWalletAdapter,
	SolflareWalletAdapter,
	TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets"

import {
	ConnectionProvider,
	WalletProvider,
} from "@solana/wallet-adapter-react"
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui"
import { createTheme, ThemeProvider } from "@mui/material"
import { web3 } from "@project-serum/anchor"

const theme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: "#FAF1D6"
		}
	},
})

const getCandyMachineId = (): web3.PublicKey | undefined => {
	try {
		const candyMachineId = new web3.PublicKey(
			process.env.REACT_APP_CANDY_MACHINE_ID!,
		)

		return candyMachineId
	} catch (e) {
		console.log("Failed to construct CandyMachineId", e)
		return undefined
	}
}

const candyMachineId = getCandyMachineId()
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!
const connection = new web3.Connection(rpcHost
	? rpcHost
	: web3.clusterApiUrl("devnet"))

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10)
const txTimeoutInMilliseconds = 30000

const App = () => {
	const endpoint = useMemo(() => clusterApiUrl(network), [])

	const wallets = useMemo(
		() => [
			new PhantomWalletAdapter(),
			new GlowWalletAdapter(),
			new SlopeWalletAdapter(),
			new SolflareWalletAdapter({ network }),
			new LedgerWalletAdapter(),
			new TorusWalletAdapter(),
		],
		[network],
	)

	return (
		<ThemeProvider theme={theme}>
			<ConnectionProvider endpoint={endpoint}>
				<WalletProvider wallets={wallets} autoConnect>
					<WalletDialogProvider sx={{
						"& .MuiList-root": {
							background: "white !important"
						}
					}}>
						<Home
							candyMachineId={candyMachineId}
							connection={connection}
							startDate={startDateSeed}
							txTimeout={txTimeoutInMilliseconds}
							rpcHost={rpcHost}
						/>
					</WalletDialogProvider>
				</WalletProvider>
			</ConnectionProvider>
		</ThemeProvider>
	)
}

export default App
